import React, { Component } from "react";
import { NavLink } from "react-router-dom";
//import { Accordion, Card, Button } from 'react-bootstrap';


class Sidebar extends Component {


    constructor() {
        super();
        this.state = {
            toggleSidebar: false
        }
    }

    state={
        realStateAgents:"",
        thirdPartyClass:"",
        internalClass:"",
        connecterClass:"",
        consumerClass:"",
        globleLogClass:"",
        emailSetUpClass:"",
        consumerLogClass:"",
    }
    toggleSidebar = () => {
        this.setState({
            toggleSidebar: !this.state.toggleSidebar
        }, () => {
            if (this.state.toggleSidebar) {
                document.getElementsByTagName('body')[0].classList.add('sidebar-toggled')
            } else {
                document.getElementsByTagName('body')[0].classList.remove('sidebar-toggled')
            }
        })
    }

    
componentDidMount(){
    if(this.props.location?.pathname ==="/admin/listrealestate" || 
    this.props.location?.pathname ==="/admin/addrealestate" || 
    this.props.location.pathname.includes("/admin/addinternalcrm/edit")){
        this.setState({
            ...this.state,
            realStateAgents:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            realStateAgents:"nav-NavLink"
        })

    }

    if(this.props.location?.pathname ==="/admin/addthirdparty" || 
    this.props.location?.pathname ==="/admin/listthirdparty" || 
    this.props.location.pathname.includes("/admin/addthirdparty/edit") ||
   this.props.location.pathname.includes("/admin/addWebhook/")
    ){
        this.setState({
            ...this.state,
            thirdPartyClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            thirdPartyClass:"nav-NavLink"
        })

    }
    if(this.props.location?.pathname ==="/admin/listinternalcrm" || 
    this.props.location?.pathname ==="/admin/addinternalcrm" || 
    this.props.location.pathname.includes("/admin/addinternalcrm/edit")){
        this.setState({
            ...this.state,
            internalClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            internalClass:"nav-NavLink"
        })

    }

    if(this.props.location?.pathname ==="/admin/addconnecters" || 
    this.props.location?.pathname ==="/admin/listconnecters" || 
    this.props.location.pathname.includes("/admin/addconnectors/edit")){
        this.setState({
            ...this.state,
            connecterClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            connecterClass:"nav-NavLink"
        })

    }

    if(this.props.location?.pathname ==="/admin/addconsumer" || 
    this.props.location?.pathname ==="/admin/listconsumer" || 
    this.props.location.pathname.includes("/admin/addconsumer/edit")){
        this.setState({
            ...this.state,
            consumerClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            consumerClass:"nav-NavLink"
        })

    }

    if(this.props.location?.pathname ==="/admin/listGlobleLog" ||
    this.props.location.pathname.includes("/admin/logDetail/view/")
    ){  
        this.setState({
            ...this.state,
            globleLogClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            globleLogClass:"nav-link"
        })

    }
    if(this.props.location?.pathname ==="/admin/ListConsumerLog" ||
    this.props.location.pathname.includes("/admin/ConsumerLogDetails/view/")
    ){ 
        this.setState({
            ...this.state,
            consumerLogClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            consumerLogClass:"nav-NavLink"
        })

    }


    if(this.props.location?.pathname ==="/admin/addemailsetup" ){
        this.setState({
            ...this.state,
            emailSetUpClass:"nav-NavLink is-active"
        })

    }else{
        this.setState({
            ...this.state,
            emailSetUpClass:"nav-NavLink"
        })

    }
}

    render() { 
               
        return (
            <React.Fragment>
                
                <div className="menu-list">
                <nav className="navbar navbar-expand-lg navbar-light">
                    <NavLink className="d-xl-none d-lg-none" to="#">Dashboard</NavLink>
                    <button className="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarNav" aria-controls="navbarNav" aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>
                    <div className="collapse navbar-collapse" id="navbarNav">
                        <ul className="navbar-nav flex-column">
                            <li className="nav-divider">
                                {/* <span id="collapse_lp" className="collapse_lp">
                                {/* <i className="fal fa-angle-left"></i> 
                                </span> */}
                            </li>
          
                            {/* <li className="nav-item ">
                                <a className="nav-link" href="/admin/listrealestate"><i className="fal fa-tachometer-fast"></i> <span>Dashboard</span></a>
                            </li> */}



                            <li className="nav-item ">
                            <NavLink  to="/admin/listrealestate" className="nav-link" activeClassName='is-active' > <i className="fad fa-file-user"></i><span> Real Estate Agent</span></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink  to="/admin/listthirdparty" className="nav-link" activeClassName='is-active' > <i className="fal fa-user-cog"></i><span> Third Party CRM</span></NavLink>
                        </li>


                        <li className="nav-item ">
                            <NavLink  to="/admin/listinternalcrm" className="nav-link" activeClassName='is-active' >
                                 <i className="fal fa-cogs"></i><span>Internal CRM</span></NavLink>
                        </li>

                        <li className="nav-item">
                           <NavLink  to="/admin/listconnecters" className="nav-link" activeClassName='is-active'>
                                 <i className="far fa-link"></i><span> Connectors</span></NavLink>
                        </li>

                        <li className="nav-item">
                            <NavLink  to="/admin/listconsumer" className="nav-link" activeClassName='is-active'>
                                 <i className="far fa-external-link "></i><span> Direct To Consumer</span></NavLink>
                        </li>
                        <li className="nav-item">
                            <NavLink  to="/admin/ListConsumerLog" className="nav-link" activeClassName='is-active'>
                                 <i className="far fa-external-link "></i><span> Direct To Consumer Log</span></NavLink>
                        </li>
                            {/* <li className="nav-item">
                                <a className="nav-link active" href="/admin/listrealestate"><i className="fad fa-file-user"></i> <span>Real Estate Agent</span></a>
                            </li>
							<li className="nav-item">
                                <a className="nav-link" href="/admin/listthirdparty"><i className="fal fa-user-cog"></i> <span>Third Party CRM</span></a>
                            </li> */}
							{/* <li className="nav-item">
                                <a className="nav-link" href="/admin/listinternalcrm"><i className="fal fa-cogs"></i> <span>Internal CRM</span></a>
                            </li>
							<li className="nav-item">
                                <a className="nav-link" href="/admin/listconnecters"><i className="far fa-link"></i> <span>Connectors</span></a>
                            </li> */}
							<li className="nav-item">
                                <NavLink  to="/admin/listGlobleLog" className="nav-link" activeClassName='is-active'><i className="fal fa-globe-stand"></i> <span>Global Connectors Log</span></NavLink>
                            </li>
                            <li className="nav-item">
                                <NavLink  to="/admin/addemailsetup" className="nav-link" activeClassName='is-active'><i className="fal fa-envelope"></i> <span>Set-Up</span></NavLink>
                            </li>
                        </ul>
                    </div>
                </nav>
            </div>
       
            </React.Fragment >
        )
    }
}

export default Sidebar;