import React, { Component } from "react";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import moment from 'moment'
import {
  AddThirdPartyData, FetchThirdList,
  FetchThirdPartyById, UpdateThirdPartyData, FetchAgentsOption, FetchCrmExternalOption,
  DeleteThridParty
} from "../../../../actions/RealEstateAction"
import { propTypes } from "react-bootstrap/esm/Image";
import AddThirdParty from "./AddThirdParty";
import ListThirdParty from "./ListThirdParty";
import validateThiredPartyForm from "../../../validation_rules/ThirdPartyFormRule";
import Pagination from "../../../common/Pagination";
import PopupModal from "../../../common/PopupModal";
import Loading from "../../../common/Loading";

class ManageThirdParty extends Component {
  constructor() {
    super();
  }
  initState = {
    agentid: 0,
    crmname: 0,
    environment: "1",
    apiendpoints: "",
    apikeys: "",
    apitokens: "",
    otherinformations: "",
    accountid: 0,
    status: 1,
    popupModalType: 0,
    popupModalViewType: 15,
    schedule_start_time: "",
    schedule_interval: "",
    is_schedule: "0"

  }

  state = {
    agentState: this.initState,
    mode: "List",
    errors: {},
    pageNumber: 1,
    pageSize: 10,
    totalElements: 20,
    searchText: "",
    popupModalType: 0,
    popupModalViewType: 15,
    popupTitle: "",
    deleteId: "",
    totallPage: "",
    getEndPoint: {}
  }

  onDataInputFieldHnadleChange = (e) => {
    const { errors } = this.state;
    delete errors[e.target.name];
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        [e.target.name]: e.target.value

      }
    })
  }
  onDataInputFieldHnadleChangeshedule = (e) => {
    //console.log("this is log...ww",e.moment);
    // const { errors } = this.state;
    // delete errors[e.target.name];
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        schedule_start_time: moment(e).format("HH:mm")
        // [e.target.name]: e.target.value

      }
    })
  }

  onCrmFieldHnadleChange = (e) => {

    const { errors } = this.state;

    delete errors[e.target.name];


    //console.log("this is is valu of e", e.target.value);

    if (e.target.value === "8") {

      let reciveData = this.props.crmExternalDropDawn && this.props.crmExternalDropDawn.find((item) => item.id === +e.target.value);


      this.setState({
        ...this.state,
        agentState: {
          ...this.state.agentState,
          [e.target.name]: e.target.value,
          apiendpoints: reciveData.endPointUrl,
          apikeys: "N/A",
          apitokens: "N/A",

        }

      })

    } else if (e.target.value === "0") {

      this.setState({

        ...this.state,

        agentState: {

          ...this.state.agentState,

          [e.target.name]: e.target.value,

          apiendpoints: ""
        }

      })

    } else {

      let reciveData = this.props.crmExternalDropDawn && this.props.crmExternalDropDawn.find((item) => item.id === +e.target.value);
      this.setState({
        ...this.state,
        agentState: {
          ...this.state.agentState,
          [e.target.name]: e.target.value,
          apiendpoints: reciveData.endPointUrl,
          apikeys: "",
          apitokens: ""
        }
      })
    }
  }

  onAddClick = () => {
    //this.props.history.push('../admin/addrealestate')
    this.setState({
      ...this.state,
      mode: "Add"
    })
  }

 
  
  onAddRealEstate = async () => {
    const { agentname,
      agentid,
      crmname,
      environment,
      apiendpoints,
      apikeys,
      apitokens,
      otherinformations,
      accountid, is_schedule,
      schedule_start_time,
      schedule_interval } = this.state.agentState;
    let errors = validateThiredPartyForm({
      agentname,
      agentid,
      crmname,
      environment,
      apiendpoints,
      apikeys,
      apitokens,
      otherinformations,
      accountid,
      is_schedule,
      schedule_start_time,
      schedule_interval
    });

    if (Object.entries(errors).length > 0) {
      this.setState({
        ...this.state,
        errors: errors
      })
    } else {
      const params = {
        data: this.state.agentState
      }
      const upDateParams = {
        data: this.state.agentState,
        id: this.props.thirdPartyById.id
      }
      if (this.props.thirdPartyById.id) {
        await this.props.UpdateThirdPartyData(upDateParams)
        this.props.history.push("/admin/listthirdparty")
      } else {
        this.props.AddThirdPartyData(params);
        this.props.history.push("/admin/listthirdparty")
      }
    }
  }
  onEditClick = async (reciveId) => {
    //console.log("consle.....");
    const params = {
      data: reciveId
    }
    await this.props.FetchThirdPartyById(params)
    // this.setState({
    //   ...this.state,
    //   agentState: this.props.thirdPartyById
    // })
  }

  clearSelection = () => {
    this.setState({
      popupModalType: 0,
      popupTitle: "",
    });
  }; 

  openDeleteModal = (id, type) => { 
    this.setState({
      deleteId: id,
      popupModalType: type,
      popupTitle:
        type === 1
          ? "you want to delete this!"
          : type === 2
            ? "you want to deactivate this!"
            : "you want to delete this!",
    });
  };
  onDelete = () => { 
    
     const params = {
      data: {
        sp_id: this.state.deleteId
      }
    }
    if (this.state.popupModalType > 0 && this.state.popupModalType === 1) {
      this.props.DeleteThridParty(params, {});
      this.clearSelection();
    } else if (
      this.state.popupModalType > 0 &&
      this.state.popupModalType === 2
    ) {
      this.props.StatusChange(params);
      this.clearSelection();
    } else {
      this.props.DeleteThridParty(params, {});
      this.clearSelection();

    } 
  };
  onPopupCancel = (e) => {
    this.setState({
      ...this.state,
      popupModalType: 0,
      popupTitle: "",
    });
  };



  onSearchFormdata = async (pageNumber, pageSize) => {
    this.setState({
      ...this.state,
      pageNumber: pageNumber,
      pageSize: pageSize,
      //goToPageNumber: pageNumber,

    });
    const params = {
      page: pageNumber,
      size: pageSize,
      searchText: this.state.searchText,

    };
    // const cbLoading = () => {
    //   this.setState({
    //     isLoading: "DONE",
    //   });
    // };
    await this.props.FetchThirdList(params);
    const { count, size } = this.props.paginationData1;
    let totalPage = Math.ceil(+count / +size)
    this.setState({
      totallPage: totalPage,
    });
  };


  searchHandleChange = (e) => {
    this.setState({ ...this.state, searchText: e.target.value }
      , () => { });
  };

  async componentDidMount() {
    let id = this.props.match.params?.id
    await this.props.FetchThirdList();
    await this.props.FetchAgentsOption();
    await this.props.FetchCrmExternalOption();
    this.findEndPoint()
    this.onSearchFormdata(this.state.pageNumber, this.state.pageSize)
    const params = {
      data: id
    }
    await this.props.FetchThirdPartyById(params)

  }
  findEndPoint = () => {
    let recivedata = this.props.crmExternalDropDawn && this.props.crmExternalDropDawn.find(item => item.endPointUrl);
    this.setState({
      ...this.state,
      getEndPoint: recivedata,
    })
  }
  getDataOnEditMode = async () => {
    const params = {
      data: this.props.thirdPartyById?.id
    }
    await this.props.FetchThirdPartyById(params)
    this.setState({
      ...this.state,
      agentState: {
        ...this.state.agentState,
        agentid: this.props.thirdPartyById?.agentId,
        crmname: this.props.thirdPartyById?.crmId,
        environment: this.props.thirdPartyById?.environment,
        apiendpoints: this.props.thirdPartyById?.apiEndPoint,
        apikeys: this.props.thirdPartyById?.apiKey,
        apitokens: this.props.thirdPartyById?.apiToken,
        otherinformations: this.props.thirdPartyById?.otherInformation,
        accountid: (this.props.thirdPartyById?.accountId != "") ? this.props.thirdPartyById?.accountId : 0,
        status: this.props.thirdPartyById?.status,
        schedule_start_time: this.props.thirdPartyById?.schedule_start_time,
        schedule_interval: this.props.thirdPartyById?.schedule_interval,
        is_schedule: this.props.thirdPartyById?.is_schedule

      }
    })
  }
  componentDidUpdate(prevProps, prevState) {
    if (this.props.thirdPartyById.id !== prevProps.thirdPartyById.id) {
      this.getDataOnEditMode();
    }
    if (this.state.searchText !== prevState.searchText) {
      if (this.state.searchText.length > 2) {
        this.onSearchFormdata(1, this.state.pageSize);
      } else if (this.state.searchText.length === 0) {
        this.onSearchFormdata(1, this.state.pageSize);
      }
    }

  }
  render() {
    const { count, size } = this.props?.paginationData1;
    return (
      <React.Fragment>
        {this.props.location.pathname === "/admin/listthirdparty/addthirdparty" &&
          <AddThirdParty
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onDataInputFieldHnadleChangeshedule={this.onDataInputFieldHnadleChangeshedule}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
            errors={this.state.errors}
            thirdPartyById={this.props.thirdPartyById}
            agentsDropDawn={this.props.agentsDropDawn}
            crmExternalDropDawn={this.props.crmExternalDropDawn}   
          />
        }

        {this.props.location.pathname.includes("/admin/listthirdparty/addthirdparty/edit") &&
          <AddThirdParty
            agentState={this.state.agentState}
            onDataInputFieldHnadleChange={this.onDataInputFieldHnadleChange}
            onDataInputFieldHnadleChangeshedule={this.onDataInputFieldHnadleChangeshedule}
            onCrmFieldHnadleChange={this.onCrmFieldHnadleChange}
            onAddRealEstate={this.onAddRealEstate}
            errors={this.state.errors}
            thirdPartyById={this.props.thirdPartyById}
            agentsDropDawn={this.props.agentsDropDawn}
            crmExternalDropDawn={this.props.crmExternalDropDawn} 
          />
        }
       
        {this.props.location.pathname === "/admin/listthirdparty" &&
          (<>
            {this.props.pending && <Loading />}

            <ListThirdParty
              thirdPartyReciveData={this.props.thirdPartyReciveData}
              onAddClick={this.onAddClick}                          
              onEditClick={this.onEditClick}
              searchHandleChange={this.searchHandleChange}
              searchText={this.state.searchText}
              openDeleteModal={this.openDeleteModal}
              
            />
            <PopupModal
              popupId="detele-popup"
              onPopup={this.onDelete}
              type={this.state.popupModalType ? this.state.popupModalType : ""}
              popupText={this.state.popupTitle}
              onCancel={this.onPopupCancel}
            />
           
            <Pagination
              // pageNumber={this.props.pageNumber}
              // pageSize={props.pageSize}
              // totalElements={totalElements}
              // totalPages={totalPages}
              updateView={this.onSearchFormdata}
              pageNumber={this.state.pageNumber}
              pageSize={size}
              totalElements={count}
              totalPages={this.state.totallPage}
              // value={props.goToPageNumber}
              handlePageChangeValue={this.props.handlePageChangeValue}
            // goToPage={props.goToPage}
            />
          </>)

        }
      </React.Fragment>
    );
  }
}

//export default ManageSpecies;

const mapStateToProps = (state) => {
  let {
    AgentsReducer: {
      pending,
      error,
      thirdPartyReciveData,
      thirdPartyById,
      agentsDropDawn,
      crmExternalDropDawn,
      paginationData1
    }
  } = state;

  return {
    pending: pending,
    error: error,
    thirdPartyReciveData: thirdPartyReciveData,
    thirdPartyById: thirdPartyById,
    agentsDropDawn: agentsDropDawn,
    crmExternalDropDawn: crmExternalDropDawn,
    paginationData1: paginationData1
  };
};

ManageThirdParty.propTypes = {
  AddThirdPartyData: PropTypes.func,
  FetchThirdList: PropTypes.func,
  FetchAgentsOption: PropTypes.func,
  FetchCrmExternalOption: PropTypes.func,
  FetchRealAgentById: PropTypes.func,
  FetchThirdPartyById: PropTypes.func,
  UpdateThirdPartyData: PropTypes.func,
  UpdateRealAgentData: PropTypes.func,
  DeleteThridParty: PropTypes.func,
  //history: PropTypes.object,
  pending: PropTypes.bool,
  error: PropTypes.object,
};
export default connect(mapStateToProps, {
  AddThirdPartyData,
  FetchThirdList,
  FetchAgentsOption,
  FetchCrmExternalOption,
  FetchThirdPartyById,
  UpdateThirdPartyData,
  DeleteThridParty
})(ManageThirdParty);