import React, { Component } from "react";
import { Switch, Route } from "react-router-dom";
import PrivateRoute from "./PrivateRoute";
import Dashboard from "../module/admin/Dashboard";
import NoMatch from "./NoMatch";
import ListRealEstate from "../module/admin/RealEstateEgents/ManageRealEstate";
import AddRealEstate from "../module/admin/RealEstateEgents/ManageRealEstate";
import ListThirdParty from "../module/admin/ThirdParty/ManageThirdParty";
import AddThirdParty from "../module/admin/ThirdParty/ManageThirdParty";
import AddInternal from "../module/admin/InternelCrm/ManageInternalCrm";
import ListInternalCrm from "../module/admin/InternelCrm/ManageInternalCrm";
import ListConnectrs from "../module/admin/Connecters/ManageConnecters";
import AddConnecters from "../module/admin/Connecters/ManageConnecters";
import AddConsumer from "../module/admin/Consumer/ManageConsumer";
import ListConsumer from "../module/admin/Consumer/ManageConsumer";
import AddEmailSetUp from "../module/admin/EmailSetup/ManageEmailSetup";
import ListGlobleLog from "../module/admin/GlobleConnectorLog/ManageGlobleConnector";
import LogHistory from "../module/admin/GlobleConnectorLog/ManageGlobleConnector";
import ListConsumerLog from "../module/admin/ConsumerLog/ManageConsumerLog";
import LogConsumerDetails from "../module/admin/ConsumerLog/ManageConsumerLog";
import HomePage from "../module/admin/Comman/HomePage";
import CMSPage from "../module/admin/frontEnd/CMSPage";
import AddWebhook from "../module/admin/Comman/AddWebHook";


const PrivateRouteArr = [
  {
    path: `/admin`,
    exact: true,
    component: ListRealEstate,
  },
  {
    path: `/admin/listrealestate`,
    exact: true,
    component: ListRealEstate,
  },
  {
    path: `/admin/`,
    exact: true,
    component: ListRealEstate,
  },

  {
    path: `/admin/listrealestate/addrealestate`,
    exact: true,
    component: AddRealEstate,
  },

  {
    path: `/admin/listrealestate/addrealestate/:mode/:id`,
    exact: true,
    component: AddRealEstate,
  },
  {
    path: `/admin/listthirdparty`,
    exact: true,
    component: ListThirdParty,
  },

  {
    path: `/admin/listthirdparty/addthirdparty`,
    exact: true,
    component: AddThirdParty,
  },

  {
    path: `/admin/listthirdparty/addthirdparty/:mode/:id`,
    exact: true,
    component: AddThirdParty,
  },



  {
    path: `/admin/listinternalcrm`,
    exact: true,
    component: ListInternalCrm,
  },

  {
    path: `/admin/listinternalcrm/addinternalcrm`,
    exact: true,
    component: AddInternal,
  },

  {
    path: `/admin/listinternalcrm/addinternalcrm/:mode/:id`,
    exact: true,
    component: AddInternal,
  },

  {
    path: `/admin/listconnecters`,
    exact: true,
    component: ListConnectrs,
  },
  {
    path: `/admin/listconnecters/addconnecters`,
    exact: true,
    component: AddConnecters,
  },

  {
    path: `/admin/listconnecters/addconnectors/:mode/:id`,
    exact: true,
    component: AddConnecters,
  },
  {
    path: `/admin/listconsumer`,
    exact: true,
    component: ListConsumer,
  },
  {
    path: `/admin/listconsumer/addconsumer`,
    exact: true,
    component: AddConsumer,
  },

  {
    path: `/admin/listconsumer/addconsumer/:mode/:id`,
    exact: true,
    component: AddConsumer,
  },
  {
    path: `/admin/addemailsetup`,
    exact: true,
    component: AddEmailSetUp,
  },

  {
    path: `/admin/listGlobleLog`,
    exact: true,
    component: ListGlobleLog,
  },
  {
    path: `/admin/listGlobleLog/logDetail/:mode/:id`,
    exact: true,
    component: LogHistory,
  },
  {
    path: `/admin/ListConsumerLog`,
    exact: true,
    component: ListConsumerLog,
  },
  {
    path: `/admin/ListConsumerLog/ConsumerLogDetails/:mode/:id`,
    exact: true,
    component: LogConsumerDetails,
  },

  {
    path: `admin/homepage`,
    exact: true,
    component: HomePage,
  },
  {
    path: `admin/cmspage`,
    exact: true,
    component: CMSPage,
  },

  {
    path: `/admin/addWebhook/:mode/:id/:againtId`,
    exact: true,
    component: AddWebhook,
  },
];

class PrivateNav extends Component {
  render() {
    return (
      <Switch>
        {PrivateRouteArr.map(
          ({ path, exact, component: Component, ...rest }) => {
            return (
              <PrivateRoute
                key={path}
                path={path}
                exact={exact}
                component={Component}
                props={this.props}
                {...rest}
              />
            );
          }
        )}
        <Route render={(props) => <NoMatch {...this.props} />} />
      </Switch>
       
    );
  }
}
export default PrivateNav;